import { Auth } from 'aws-amplify';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

export async function getUserAliasFromAuth() {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().payload.identities[0].userId;
}

export async function getToken() {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().getJwtToken();
}

export async function getCredentialsFromAWSService() {
  let COGNITO_LOGIN: any = {};
  COGNITO_LOGIN[getCognitoDomain()] = await getToken();
  return fromCognitoIdentityPool({
    clientConfig: { region: 'us-west-2' },
    identityPoolId: 'us-west-2:d32ea07d-8bbf-479d-9f45-76248ffc1761',
    logins: COGNITO_LOGIN
  });
}

function getCognitoDomain() {
  const REGION: any = 'us-west-2';
  const USER_POOL_ID = 'us-west-2_0PzWMQNuw';
  return 'cognito-idp.' + REGION + '.amazonaws.com/' + USER_POOL_ID;
}
