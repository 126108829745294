import {
  GetObjectCommand,
  GetObjectCommandInput,
  ListObjectsV2Command,
  ListObjectsV2CommandInput,
  S3Client,
  S3ClientConfig
} from '@aws-sdk/client-s3';
import { GetSecretValueCommand, GetSecretValueResponse, SecretsManagerClient, SecretsManagerClientConfig } from '@aws-sdk/client-secrets-manager';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { LicenseManager } from 'ag-grid-enterprise';
import { getCredentialsFromAWSService } from './AuthServices';
import { logger } from './Logger';
import { QuickSightClient, QuickSightClientConfig } from '@aws-sdk/client-quicksight';
import { SNSClient, PublishCommand, PublishCommandInput, PublishCommandOutput } from '@aws-sdk/client-sns';

export const ENV_CONSTANTS = Object.freeze({
  ENVIRONMENT_VARIABLES: {
    Region: 'us-west-2',
    Stage: 'prod',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'dq9m8obrlmhri.cloudfront.net'
  }
});

export const configureAgGridLicense = async (): Promise<string> => {
  const licenseKey = (await retrieveAGGridEnterpriseLicenseKey()).SecretString;
  if (licenseKey) {
    LicenseManager.setLicenseKey(JSON.parse(licenseKey)['ag-grid-enterprise-license-key']);
    logger.info('Ag-Grid License configured successfully!');
    return new Promise((resolve) => resolve('setLicenseKey_Success'));
  } else {
    logger.error('Ag-Grid License setup failed!');
    return new Promise((reject) => reject('setLicenseKey_Failed'));
  }
};

export const retrieveAGGridEnterpriseLicenseKey = async (): Promise<GetSecretValueResponse> => {
  const REGION = 'us-west-2';
  const SECRET_MANAGER_ARN = getSecretARNForAGGridLicenseKey();
  const secretsManageClientConfig: SecretsManagerClientConfig = {
    region: REGION,
    credentials: await getCredentialsFromAWSService()
  };
  const secretsManagerClient = new SecretsManagerClient(secretsManageClientConfig);
  const command = new GetSecretValueCommand({ SecretId: SECRET_MANAGER_ARN });
  return await secretsManagerClient.send(command);
};

// Katal Logger isn't configured by the time this function is called.
// Used only in test environment
export const retrieveAPIKey = async (): Promise<GetSecretValueResponse> => {
  const REGION = 'us-west-2';
  const SECRET_MANAGER_ARN = 'arn:aws:secretsmanager:us-west-2:645196202724:secret:msp-app-apikey-secret-Oca6fN';
  const secretsManageClientConfig: SecretsManagerClientConfig = {
    region: REGION,
    credentials: await getCredentialsFromAWSService()
  };
  const secretsManagerClient = new SecretsManagerClient(secretsManageClientConfig);
  const command = new GetSecretValueCommand({ SecretId: SECRET_MANAGER_ARN });
  return await secretsManagerClient.send(command);
};

export async function getS3Client() {
  const REGION = 'us-west-2';
  const s3ClientConfig: S3ClientConfig = {
    region: REGION,
    credentials: await getCredentialsFromAWSService()
  };
  const client = new S3Client(s3ClientConfig);
  return client;
}

export const getListOfObjectsFromKeys = async (bucket: string, s3Key: string) => {
  const s3Client = await getS3Client();
  const listCommandInput: ListObjectsV2CommandInput = {
    Bucket: bucket,
    StartAfter: s3Key
  };
  const listCommand = new ListObjectsV2Command(listCommandInput);
  const listOfObjects = await s3Client.send(listCommand);
  return listOfObjects;
};

export const getPresignedURL = async (bucket: string, s3Key: string) => {
  const s3Client = await getS3Client();
  const commandInput: GetObjectCommandInput = {
    Bucket: bucket,
    Key: s3Key
  };
  const command = new GetObjectCommand(commandInput);
  const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 }); // expiresIn -  The number of seconds before the presigned URL expires
  return url;
};

const getQuickSightClient = async () => {
  const REGION = 'us-west-2';
  const quickSightClientConfig: QuickSightClientConfig = {
    region: REGION,
    credentials: await getCredentialsFromAWSService()
  };
  const client = new QuickSightClient(quickSightClientConfig);
  return client;
};

// to send SNS Notification
// TODO - Make it more generic for more user actions
export const sendSNSNotification = async (subject: string, message: string) => {
  try {
    const REGION = 'us-west-2';
    const ARN = 'arn:aws:sns:us-west-2:660309912044:DMM-Slack-Notification-prod';

    const snsClient: SNSClient = new SNSClient({
      region: REGION,
      credentials: await getCredentialsFromAWSService()
    });
    const commandInput: PublishCommandInput = {
      TopicArn: ARN,
      Subject: subject,
      Message: message
    };
    const publishCommand = new PublishCommand(commandInput);
    const publishCommandOutput: PublishCommandOutput = await snsClient.send(publishCommand);
    logger.info('SNS Notification sent');
  } catch (error: any) {
    logger.error('Unable to send SNS Notification', error);
  }
};

export function getSecretARNForAGGridLicenseKey(stage = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Stage): string {
  const devAndAlphaSecretKey = 'arn:aws:secretsmanager:us-west-2:645196202724:secret:ag-grid-license-key-alpha-CfGazp';
  switch (stage) {
    case 'test':
      return devAndAlphaSecretKey;
    case 'alpha':
      return devAndAlphaSecretKey;
    case 'beta':
      return 'arn:aws:secretsmanager:us-west-2:796253333870:secret:ag-grid-license-key-beta-nlb4jp';
    case 'prod':
      return 'arn:aws:secretsmanager:us-west-2:660309912044:secret:ag-grid-license-key-prod-S3CmsL';
    default:
      throw new Error('SecretARN is not configured');
  }
}
